var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "ssl-btn",
      class: {
        plain: _vm.plain,
        filling: !_vm.plain,
        customClass: _vm.customClass,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("btn-click")
        },
      },
    },
    [_vm._v(_vm._s(_vm.btnText))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }